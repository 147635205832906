<template>
  <div class="w-full">
    <div class="report-title">
      <h1 class="text-2xl font-bold">Reporte de taquilla</h1>
      <hr />
    </div>
    <div class="report-container">
      <div class="report-form mr-5 text-center">
        <div class="text-lg font-semibold mb-1">Rango de Fechas</div>
        <div class="flex flex-column card text-left">
          <div class="flex flex-column mb-3">
            <label for="fromDate">Desde</label>
            <input
              type="date"
              id="fromDate"
              v-model="fromDate"
              :disabled="loading_data"
            />
          </div>
          <div class="flex flex-column mb-3">
            <label for="toDate">Hasta</label>
            <input
              type="date"
              id="toDate"
              v-model="toDate"
              :disabled="loading_data"
            />
          </div>
        </div>
        <div class="flex flex-row">
          <Button
            class="btn btn-primary"
            @click="getData"
            :loading="loading_data"
            >Generar reporte</Button
          >
        </div>
      </div>
      <div class="text-right">
        <div class="flex justify-content-end w-full align-items-center">
          <span class="font-semibold underline mr-auto" v-if="report_generated"
            >{{ moment(reportFromDate).format("DD/MM/YYYY") }} al
            {{ moment(reportToDate).format("DD/MM/YYYY") }}</span
          >
          <Button
            class="btn btn-primary"
            @click="printReport"
            :disabled="loading_data || !report_generated"
            ><i class="pi pi-print pi-color mr-2" style="font-size: 1.2rem"></i>
            Imprimir</Button
          >
        </div>
        <div class="report-table card w-full mt-3">
          <div class="flex flex-column w-full">
            <div class="flex flex-row w-full row-title">
              <div class="table-title font-semibold mb-1 mr-1 text-left">
                Descripción
              </div>
              <div class="table-title font-semibold mb-1 mr-1">Monto BUSD</div>
              <div class="table-title font-semibold mb-1 mr-1">Monto VES</div>
            </div>
            <div
              v-for="(item, index) in data"
              :key="index"
              class="w-full"
              :class="`row-${index % 2}`"
            >
              <div class="flex flex-row justify-content-between">
                <div class="table-column text-left border-right-1">
                  {{ item.description }} {{ item.porcentage }}
                </div>
                <div class="table-column">{{ item.amount_busd }}</div>
                <div class="table-column">{{ item.amount_ves }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HTTP from "@/service/http";
import moment from "moment";

const report_data = [

  {
    key: "total_raw_sales",
    description: "Ventas Animalitos",
    amount_busd: "",
    amount_ves: "",
    porcentage:"",
  },
  {
    key: "profit_report",
    description: "Comisión Animalitos",
    amount_busd: "",
    amount_ves: "",
    porcentage:"",
  },
  // {
  //   key: "ventasBrutasAnim",
  //   description: "Ventas Animalitos",
  //   amount_busd: "",
  //   amount_ves: "",
  //   porcentage:"",
  // },
  {
    key: "total_wallet_inputs",
    description: "Depósitos",
    amount_busd: "",
    amount_ves: "",
    porcentage:"",
  },
  {
    key: "total_wallet_outputs",
    description: "Retiros",
    amount_busd: "",
    amount_ves: "",
    porcentage:"",
  },
  {
    key: "total_wallet_sales",
    description: "Ventas por saldo de taquilla",
    amount_busd: "",
    amount_ves: "",
    porcentage:"",
  },
  {
    key: "initial_balance",
    description: "Saldo inicial - wallet",
    amount_busd: "",
    amount_ves: "",
    porcentage:"",
  },
  {
    key: "final_balance",
    description: "Saldo final - wallet",
    amount_busd: "",
    amount_ves: "",
    porcentage:"",
  },
  {
    key: "fiat_balance",
    description: "Saldo de caja chica",
    amount_busd: "",
    amount_ves: "",
    porcentage:"",
  },
];
export default {
  name: "Reports",
  data() {
    return {
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      reportFromDate: moment().format("YYYY-MM-DD"),
      reportToDate: moment().format("YYYY-MM-DD"),
      loading_data: false,
      report_generated: false,
      data: [...report_data],
      moment: moment,
    };
  },
  methods: {
    getData() {
      this.loading_data = true;
      this.data = [...report_data];
      this.report_generated = false;
      HTTP(false, 7000)
        .get(
          `/ticketoffices/report?start-date=${this.fromDate}&end-date=${this.toDate}`
        )
        .then((resp) => {
          if (resp.data.status === "SUCCESS") {
            this.data.forEach((e) => {
              if(e.key === "profit_report")
              {
                e.amount_busd = resp.data.data[e.key][0].BUSD
                e.amount_ves = resp.data.data[e.key][0].VES
                e.porcentage = "("+resp.data.data[e.key][0].service_porcentage+"%)"
              //  e["ventasBrutasAnim"].amount_busd = (100* parseFloat(resp.data.data[e.key][0].BUSD))/parseFloat(resp.data.data[e.key][0].service_porcentage);
              //  e["ventasBrutasAnim"].amount_ves = (100* parseFloat(resp.data.data[e.key][0].VES))/parseFloat(resp.data.data[e.key][0].service_porcentage);
              }
              else{
      
              //console.log(e.key);
               e.amount_busd = resp.data.data[e.key].BUSD;
               e.amount_ves = resp.data.data[e.key].VES;
              }

            });
            this.reportFromDate = this.fromDate;
            this.reportToDate = this.toDate;
            this.report_generated = true;
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.response)
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 7000,
            });
        })
        .finally(() => {
          this.loading_data = false;
        });
    },
    printReport() {
      let ticket_text = `
          <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; padding: 0px; font-size: 10px; min-width: 92vw; width: 97%;">
            <tr>
              <td colspan="3" style="height: 10px; text-align: center; border: 0px; padding-bottom: 10px">
                <span class="text-center" style="font-size: 11px; width: 100vw">Reporte de ventas de taquilla</span>
                <span class="text-center" style="font-size: 11px; width: 100vw">
                  Del ${this.moment(this.reportFromDate).format("DD/MM/YYYY")} al ${this.moment(this.reportToDate).format("DD/MM/YYYY")}</span>
              </td>
            </tr>
            <tr>
              <td style="width: 30%; border-bottom: solid 1px">
                Descripción
              </td>
              <td style="width: 35%; text-align: right; border-bottom: solid 1px">Monto BUSD</td>
              <td style="width: 35%; text-align: right; border-bottom: solid 1px">Monto VES</td>
            </tr>
      `;
      this.data.forEach((e) => {
        ticket_text += `
            <tr>
              <td style="border-bottom: solid 1px">
                ${e.description} ${e.porcentage}
              </td>
              <td style="text-align: right; border-bottom: solid 1px">${this.formatNumberToLocale(
                e.amount_busd
              )}</td>
              <td style="text-align: right; border-bottom: solid 1px">${this.formatNumberToLocale(
                e.amount_ves
              )}</td>
            </tr>
        `;
      });
      ticket_text += `
          </table>
      `;
      
      const printWindow = document.createElement("iframe");
      printWindow.style.display = "none";
      document.body.appendChild(printWindow);

      // Agregar el texto sin formato al iframe
      printWindow.contentDocument.write(
        "<pre style='margin: 0; padding: 0;'>" +
          ticket_text +
          "</pre>"
      );
      printWindow.contentDocument.close();

      // Imprimir y eliminar el iframe
      printWindow.contentWindow.print();
      printWindow.parentNode.removeChild(printWindow);
    },
    formatNumberToLocale(number, locale = "es-VE") {
      return Number(number).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style scoped>
.report-container {
  font-family: "Roboto";
  font-style: normal;
}
.report-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 270px;
}
.report-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
}
.report-table {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: fix-content;
  min-width: 370px;
}
.table-title,
.table-column {
  width: auto;
  min-width: 200px;
  max-width: 200px;
  border-color: rgba(64, 64, 64, 0.25);
}
.table-column {
  padding: 10px;
}
.row-title {
  background: #404040;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px 6px 0px 0px;
  padding: 10px;
  color: #fff;
}
.row-0 {
  background: #e5e5e5;
}
.row-1 {
  background: #ffffff;
}
@media print {
  .report-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .report-form {
    display: none;
  }
}
</style>
